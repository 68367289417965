/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../../Repository/Api";
import ServiceOrderCard from "../OrderCard/ServiceOrderCard";

const ServiceOrder = ({ isSliced, heading, padded, isMore }) => {
  const [order, setOrder] = useState([]);
  const [addOnServicePresent, setAddOnServicePresent] = useState(false);
  const [regularServicePresent, setRegularServicePresent] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: `api/v1/serviceOrders?serviceStatus=Pending`,
      setResponse: setOrder,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    const isAnyAddOnServicePresent = order?.data?.some(
      (item) => item?.AddOnservicesSchema?.length > 0
    );
    setAddOnServicePresent(isAnyAddOnServicePresent);
  }, [order]);

  useEffect(() => {
    const isRegularServicePresent = order?.data?.some(
      (item) => item?.services?.length > 0
    );
    setRegularServicePresent(isRegularServicePresent);
  }, [order]);

  console.log(order?.data)

  return (
    <>
      <div className={`${!padded && "user_product_order_container"}`}>
        <div className="user_product_order">
          {order?.data?.length === 0 ? (
            <div className="Not-Found">
              <img src="/Image/out-of-stock.png" alt="" />
              <h5> You have no upcoming service orders.</h5>
            </div>
          ) : (
            <>
              {!heading && <p className="title">Upcoming Service</p>}
              {isSliced === true ? (
                <>
                  {regularServicePresent === true ? (
                    <div className="service-upcoming-order">
                      {order?.data
                        ?.slice(0, 4)
                        ?.map((item) =>
                          item?.services?.map((i) => (
                            <ServiceOrderCard
                              img={i.serviceId?.images?.[0]?.img}
                              title={i?.serviceId?.name}
                              price={
                                i.memberprice
                                  ? i.memberprice
                                  : i?.serviceId?.price
                              }
                              orderId={item?.orderId}
                              time={item?.toTime}
                              offerDiscount={item?.offerDiscount}
                              membershipDiscount={item?.memberShip}
                              serviceId={i?.serviceId?._id}
                              appointmentId={item?._id}
                              key={i?.serviceId?._id}
                              size={i?.size}
                            />
                          ))
                        )}
                    </div>
                  ) : (
                    <div className="Not-Found">
                      <img src="/Image/out-of-stock.png" alt="" />
                      <h5> No regular services are present.</h5>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {regularServicePresent === true ? (
                    <div className="service-upcoming-order">
                      {order?.data?.map((item) =>
                        item?.services?.map((i) => (
                          <ServiceOrderCard
                            img={i.serviceId?.images?.[0]?.img}
                            title={i?.serviceId?.name}
                            price={
                              i.memberprice
                                ? i.memberprice
                                : i?.serviceId?.price
                            }
                            orderId={item?.orderId}
                            time={item?.toTime}
                            offerDiscount={item?.offerDiscount}
                            membershipDiscount={item?.memberShip}
                            serviceId={i?.serviceId?._id}
                            appointmentId={item?._id}
                            key={i?.serviceId?._id}
                            size={i?.size}
                          />
                        ))
                      )}
                    </div>
                  ) : (
                    <div className="Not-Found">
                      <img src="/Image/out-of-stock.png" alt="" />
                      <h5> No regular services are present.</h5>
                    </div>
                  )}
                  {addOnServicePresent === true && (
                    <>
                      <div
                        className="title_account_second"
                        style={{ textTransform: "capitalize" }}
                      >
                        AddOn Service's
                      </div>
                      {addOnServicePresent === true && (
                        <div className="service-upcoming-order">
                          {order?.data?.map((item) =>
                            item?.AddOnservicesSchema?.map((i) => (
                              <ServiceOrderCard
                                img={i.addOnservicesId?.image}
                                title={i?.addOnservicesId?.name}
                                price={i?.addOnservicesId?.price}
                                orderId={item?.orderId}
                                time={item?.toTime}
                                membershipDiscount={item?.memberShip}
                                offerDiscount={item?.offerDiscount}
                                serviceId={i?.serviceId?._id}
                                isCancel={false}
                                isReschedule={false}
                                isView={false}
                                key={`SeviceIndex${i?.serviceId?._id}`}
                                size={i?.size}
                              />
                            ))
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {order?.data?.length && isMore && (
          <Link to="/upcoming-orders"  >
            <button className="view_more_btn">View More Appointments</button>
          </Link>
        )}
      </div>
    </>
  );
};

export default ServiceOrder;
