/** @format */

import React from "react";

const FullScreenLoader = () => {
  return (
    <div className="fullscreen-spinner">
      <div className="spinner" />
    </div>
  );
};

export default FullScreenLoader;
