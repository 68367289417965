/** @format */

import React, { useState, useEffect } from "react";
import { award2, galleryImg1, galleryImg4, award4 } from "../../assest";

const Awards = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 786);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 786);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <section className="awards-ambassadors MaxComponent">
      <h5 className="heading">Awards & Ambassadorships</h5>

      <div className="container">
        <div className="item">
          <img src={award2} alt="" className="thumbnail" />
          <div>
            {isMobile ? (
              <p className="title">
                BEST IN Business <br /> 2024
              </p>
            ) : (
              <p className="title">BEST IN Business 2024</p>
            )}
          </div>
        </div>
        <div className="item">
          <img src={award4} alt="" className="thumbnail" />
          <div>
            {isMobile ? (
              <p className="title">
                People’s choice <br />
                2024 Winner{" "}
              </p>
            ) : (
              <p className="title">People’s choice 2024 Winner </p>
            )}
          </div>
        </div>
        <div className="item">
          <img src={galleryImg1} alt="" className="thumbnail" />
          <div>
            {isMobile ? (
              <p className="title">
                Aerolase <br /> Ambassador
              </p>
            ) : (
              <p className="title">Aerolase Ambassador</p>
            )}
          </div>
        </div>

        <div className="item">
          <img src={galleryImg4} alt="" className="thumbnail" />
          <div>
            {isMobile ? (
              <p className="title">
                Hydrafacial <br /> Master Hydrafacialist
              </p>
            ) : (
              <p className="title">Hydrafacial Master Hydrafacialist</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Awards;
